import { Card, Col, Row, Table } from "react-bootstrap";
import "./style.css";
export default function TabelaInfos({ dataTarget }) {
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <p className="m-0 fw-bold">INFORMAÇÕES TECNICAS</p>
          </Col>
          <Col className="text-end">
            <p className="m-0 fw-bold">{dataTarget.nome}</p>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table responsive className="responsive-table-info">
          <tbody>
            <tr>
              <th>PAI</th>
              <td>{dataTarget.dados !== undefined ? dataTarget.dados.Pai : 'Não informado.'}</td>
            </tr>
            <tr>
              <th>MÃE</th>
              <td>{dataTarget.dados !== undefined ? dataTarget.dados.Mae : 'Não informado.'}</td>
            </tr>
            <tr>
              <th>NASCIMENTO</th>
              <td>{dataTarget.dados !== undefined ? dataTarget.dados.Nascimento : 'Não informado.'}</td>
            </tr>
            <tr>
              <th>SEXO</th>
              <td>{dataTarget.dados !== undefined ? dataTarget.dados.Sexo : 'Não informado.'}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
