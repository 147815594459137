import { Card, Col, Container, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useEffect } from 'react';
import Gallery from './Galery';
import FormularioVendas from '../../components/formularios/vendas';
import './style.css';
import { useParams } from 'react-router-dom';
import { data } from '../../components/object/bois';
import TabelaInfos from './TabelaInfos';
export default function InfoBois() {
  let { id } = useParams();

  const target = data.find((obj) => obj.id === Number(id));

  useEffect(() => {
    document.title = "Sumario";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="politica-de-privacidade">
      <Container className='my-5'>
        <Row>
          <Col sm={12} lg={7} className="mb-3 order-0">
            <Card>
              <Card.Body>
                <Gallery dataTarget={target} />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} lg={5} className="mb-3 order-lg-1 order-2">
            <Card className='h-100'>
              <Card.Body>
                <FormularioVendas dataTarget={target} />
              </Card.Body>
            </Card>
          </Col>
          <Col className='mb-3 order-1 order-lg-2'>
            <TabelaInfos dataTarget={target} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
