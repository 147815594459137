import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import "./fonts/fonts.css";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import Cookies from "./pages/Cookies";
import Cabecalho from "./components/Cabecalho";
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";
import ErrorPage from './pages/404';
import Sumario from './pages/sumario/Index';
import InfoBois from './pages/sumario/infoBoi';
import Raca from './pages/Raca';
import Heterose from './pages/Heterose';
import Vantagens from './pages/Vantagens';
function App() {
  let headerStyle = { position: 'unset', background: "var(--cor-fazenda)" }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Cabecalho />}>
          <Route index element={<Home />} />
        </Route>
        <Route element={<Cabecalho PositionBlock={headerStyle} />} >
          <Route
            path="/politica-de-privacidade"
            element={<PoliticaPrivacidade />}
          />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/sumario">
            <Route index element={<Sumario />} />
            <Route path=":id" element={<InfoBois />} />
          </Route>
          <Route path='historia' element={<Raca />} />
          <Route path='heterose' element={<Heterose />} />
          <Route path='vantagens' element={<Vantagens />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Aceitar"
        cookieName="cookies_fazenda_sao_joaquim"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#5a3722", fontSize: "13px" }}
        expires={365}
      >
        Este site usa cookies para melhorar a experiência do usuário
      </CookieConsent>
    </BrowserRouter >
  );
}

export default App;
