import { useState } from "react";
import { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Gallery({ dataTarget }) {
  const [nav, setNav] = useState(null);
  const [thumb, setThumb] = useState(null);
  const slider1 = useRef()
  const slider2 = useRef()

  return (
    <div>
      <Slider className='slideSumario mySwiper position-relative' asNavFor={thumb} ref={slider1 => setNav(slider1)}>
        {dataTarget.fotos.map(fotos => (
          <div key={fotos}>
            <img key={fotos} src={`${fotos}=w800-k-iv1`} alt={dataTarget.nome} className="w-100" />
          </div>
        ))}
      </Slider>
      <Slider
        className='slideSumario mySwiper position-relative'
        asNavFor={nav}
        ref={slider1 => setThumb(slider1)}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={true}
        centerMode={true}
      >
        {dataTarget.fotos.map(fotos => (
          <div key={fotos} className="px-1">
            <img key={fotos} src={`${fotos}=w200-k-iv1`} alt={dataTarget.nome} className="w-100" />
          </div>
        ))}
      </Slider>
    </div>
  );
}
