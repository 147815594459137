import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/lazy";
import "./style.css";
import { Autoplay, EffectFade, Navigation } from "swiper";
import BANNER_SENEPOL_V3 from "./img/slide/BANNER_SENEPOL_V3.webp";
import BANNER_SENEPOL_V3_2560 from "./img/slide/2560/BANNER_SENEPOL.webp";
import BANNER_SENEPOL_V3_1024 from "./img/slide/1024/BANNER_SENEPOL_1024.webp";
import BANNER_SENEPOL_V3_414 from "./img/slide/414/BANNER_SENEPOL_414.webp";


import BANNER_TOURINO from "./img/slide/BANNER_TOURINO.webp";
import BANNER_TOURINO_414 from "./img/slide/414/BANNER_TORINO_414.webp";
import BANNER_TOURINO_1024 from "./img/slide/1024/BANNER_TOURINO_1024.webp";
import BANNER_TOURINO_2560 from "./img/slide/2560/BANNER_TOURINO.webp";


export default function SlidePrincipal() {
  return (
    <div id="slidePrincipal" className="position-relative">
      <Swiper
        slidesPerView={1}
        effect={"fade"}
        spaceBetween={0}
        loop={true}
        lazy={true}
        navigation={true}
        modules={[Navigation, Autoplay, EffectFade]}
        className="mySwiper"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        onSlideChange={(swiperCore) => {
          const { activeIndex, slides } =
            swiperCore;
          const index_currentSlide = activeIndex;
          const currentSlide = slides[index_currentSlide];
          for (let i = 0; i < slides.length; i++) {
            slides[i].children[0].classList.remove('zoom-img')
          }
          currentSlide.children[0].classList.add('zoom-img');
        }}
      >
        <SwiperSlide>
          <picture className='w-100 h-100'>
            <source srcset={BANNER_SENEPOL_V3_2560} media="(min-width: 1921px)" />
            <source srcset={BANNER_SENEPOL_V3} media="(min-width: 1280px)" />
            <source srcset={BANNER_SENEPOL_V3_1024} media="(min-width: 600px)" />
            <img src={BANNER_SENEPOL_V3_414} alt="Serviços de oficina" class="w-100" />
        </picture>
        </SwiperSlide>
        <SwiperSlide>
        <picture className='w-100 h-100'>
            <source srcset={BANNER_TOURINO_2560} media="(min-width: 1921px)" />
            <source srcset={BANNER_TOURINO} media="(min-width: 1280px)" />
            <source srcset={BANNER_TOURINO_1024} media="(min-width: 600px)" />
            <img src={BANNER_TOURINO_414} alt="Serviços de oficina" class="w-100" />
        </picture>
        </SwiperSlide>
      </Swiper>
      <div className="py-0 py-lg-2 bg-pastel borda-SlidePrincial"></div>
    </div>
  );
}
