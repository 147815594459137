import { BsInstagram } from "react-icons/bs";
import "./style.css";
import { Link, Outlet } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logoFazenda from "../../img/logo/SENEPOL TH.webp";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";

export default function Cabecalho({ PositionBlock, children }) {

  return (
    <>
      <header>
        <div className="site-header header-over" style={PositionBlock}>
          <div className="header-top">
            <div className="container-lg container-fluid wrapper">
              <Navbar className="pb-0">
                <Container fluid="lg">
                  <Navbar.Text href="#home">MÍDIAS SOCIAIS</Navbar.Text>
                  <Nav className="me-auto">
                    <Nav.Link href="https://www.instagram.com/thsenepol/" className="d-flex">
                      <BsInstagram />
                    </Nav.Link>
                  </Nav>
                  <Navbar.Toggle />
                  <Navbar className="justify-content-end">
                    <Nav className="ms-auto">
                      <Nav.Link href="https://www.instagram.com/thsenepol/" className="d-flex align-items-center">
                        <BsInstagram /> &nbsp; Entre em contato
                      </Nav.Link>
                    </Nav>
                  </Navbar>
                </Container>
              </Navbar>
            </div>
          </div>
          <div className="header-bottom">
            <div className="container wrapper">
              <div className="header-logo">
                <Link to={"/"} className='d-block text-center'>
                  <img src={logoFazenda} alt="SENEPOL TH" width={150} />
                </Link>
              </div>

              <Navbar key="lg" expand="lg" className="mb-3">
                <Container fluid>
                  <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-lg`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                    placement="end"
                    className="w-100"
                  >
                    <Offcanvas.Header
                      id="menu_fazenda_titulo"
                      className="bg-menu"
                      closeButton
                    >
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-lg`}
                        className="text-center w-100"
                      >
                        <img src={logoFazenda} alt="SENEPOL TH" width={150} />
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="bg-menu" id="menu_fazenda">
                      <Nav className="me-auto">
                        <Nav.Link
                          as={HashLink} smooth to={{pathname:'/', hash:"#th-senepol"}}  onClick={() => {
                            document.getElementById("menu_fazenda_titulo").children[1].click()
                          }}
                          className="d-flex align-items-center ps-0"
                        >
                          A FAZENDA
                        </Nav.Link>
                        <Nav.Link as={HashLink} smooth to="/historia" className="d-flex align-items-center" onClick={() => {
                            document.getElementById("menu_fazenda_titulo").children[1].click()
                          }}>
                          RAÇA
                        </Nav.Link>
                        <Nav.Link as={HashLink} smooth to="/heterose" className="d-flex align-items-center" onClick={() => {
                            document.getElementById("menu_fazenda_titulo").children[1].click()
                          }}>
                          HETEROSE
                        </Nav.Link>
                      </Nav>
                      <Nav className="ms-auto">
                        <Nav.Link
                          as={HashLink} smooth to="/sumario"
                          className="d-flex align-items-center ps-0" onClick={() => {
                            document.getElementById("menu_fazenda_titulo").children[1].click()
                          }}
                        >
                          ANIMAIS DISPONÍVEIS
                        </Nav.Link>
                        <Nav.Link as={HashLink} smooth to="/vantagens" className="d-flex align-items-center" onClick={() => {
                            document.getElementById("menu_fazenda_titulo").children[1].click()
                          }}>
                          VANTAGENS
                        </Nav.Link>
                        <Nav.Link as={HashLink} smooth to="/#contato" className="d-flex align-items-center" onClick={() => {
                            document.getElementById("menu_fazenda_titulo").children[1].click()
                          }}>
                          CONTATO
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </header>
      <div>
        <Outlet />
      </div>
    </>
  );
}
