import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { API_URL } from '../../../api/config';
import { phoneFormatter11 } from "../../FormatTelefone";

export default function FormularioVendas({ id = "FormularioVendas", dataTarget }) {
  const [form, setForm] = useState({
      nome_boi: dataTarget.nome,
      site: window.location.href,
      formulario: "TH SENEPOL",
      nome: "Site TH Senepol",
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const SITE_KEY = "6LdBi0EdAAAAAN9KMMellY4SaUmjrTIP2rB3ORjG";

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, []);

  const handleOnClick = (e) => {
    e.preventDefault();

    const formValid = document.getElementById(id);
    if (formValid.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return false;
    }

    setValidated(true);

    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then((token) => {
        submitData(token);
      });
    });
  };

  const submitData = (token) => {
    fetch(`${API_URL}/email/thsenepol`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        form,
        token: token,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.Error) {
          Swal.fire({
            title: "Não foi possível enviar o e-mail.",
            text: "Tente novamente em alguns instantes",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "E-mail enviado com sucesso",
            icon: "success",
          });
        }
      });
  };

  return (
    <Form noValidate validated={validated} method="post" id={id}>
      <h3 className="text-center">MAIS INFORMAÇÕES</h3>
      <div>
        <input type="hidden" name="nome_boi" value={dataTarget.nome} />
        <input type="hidden" name="site" value={window.location.href} />
      </div>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Nome</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Nome"
          onChange={(e) =>
            setForm({
              ...form,
              Nome_Interessado: e.target.value,
            })
          }
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Endereço de E-mail</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Endereço de E-mail"
          onChange={(e) =>
            setForm({
              ...form,
              Email: e.target.value,
            })
          }
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="Telefone">
        <Form.Label>Telefone</Form.Label>
        <Form.Control
          required
          value={form.Telefone}
          maxLength={11}
          type="text"
          placeholder="Telefone"
          onChange={(e) =>
            setForm({
              ...form,
              Telefone: phoneFormatter11(e.target.value),
            })
          }
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Mensagem</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={(e) =>
            setForm({
              ...form,
              Mensagem: e.target.value,
            })
          }
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <span className="mb-3">Receber contato através</span>
        <Form.Check
          required
          type="checkbox"
          id="check-email"
          label="E-mail"
          onChange={(e) =>
            setForm({
              ...form,
              Contato_Email: e.target.checked,
            })
          }
        />
        <Form.Check
          type="checkbox"
          id="check-telefone"
          label="Telefone"
          onChange={(e) => {
            setForm({
              ...form,
              Contato_Telefone: e.target.checked,
            });
          }}
        />
        <Form.Check
          type="checkbox"
          id="check-whatsapp"
          label="Whatsapp"
          onChange={(e) => {
            setForm({
              ...form,
              Contato_Whatsapp: e.target.checked,
            });
          }}
        />
      </Form.Group>
      <div className="text-center">
        <Button className="btn-fazenda" onClick={handleOnClick} disabled={loading}>
          {loading ? <CircularProgress size={20} className="text-white" /> : "SOLICITAR CONTATO"}
        </Button>
      </div>
    </Form>
  );
}
