import { Link } from 'react-router-dom';
import "./style.css";

export default function ErrorPage() {
  return (
    <div>
      <div className="d-flex justify-content-center align-content-center align-items-center h-100vh text-center">
        <div className='col-12'>
          <h1>404</h1>
          <h1>Página não encontrada</h1>
          <Link to={'/'}> Voltar ao Site. </Link>
        </div>
      </div>
    </div>
  );
}
