

const Header = ({children}) => {
  return (
    <>
      {children}
    </>
  );
}

export default Header;