import React from "react";
import { Card, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Fade from "react-reveal/Fade";
import capa from '../object/img/capa.webp'
import capa02 from '../object/img/capa_02.webp'
import capa03 from '../object/img/capa_04.webp'

import "./style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/lazy";
import { Link } from 'react-router-dom';

export default function Atividades() {
  return (
    <Container>
      <div id="atividades" className="position-relative">
        <Fade bottom distance="30px">
          <div className="slide_atividades position-relative">
            <Swiper
              style={{
                "--swiper-navigation-color": "#5a3722",
                "--swiper-pagination-color": "#5a3722",
              }}
              slidesPerView={3}
              spaceBetween={20}
              slidesPerGroup={1}
              loop={false}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <Card className="mx-auto shadow">
                  <Card.Img
                    variant="top"
                    src={capa}
                  />
                  <Card.Body>
                    <Card.Title className="fontAbhaya">A RAÇA</Card.Title>
                    <div className="pt-3">
                      <Link to='historia'>
                        <button
                          className="btn btn-fazenda w-75"
                        >
                          RAÇA
                        </button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className="mx-auto shadow">
                  <Card.Img
                    variant="top"
                    src={capa02}
                  />
                  <Card.Body>
                    <Card.Title className="fontAbhaya">HETEROSE</Card.Title>
                    <div className="pt-3">
                      <Link to='heterose'>
                        <button
                          className="btn btn-fazenda w-75"
                        >
                          HETEROSE
                        </button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className="mx-auto shadow">
                  <Card.Img
                    variant="top"
                    src={capa03}
                  />
                  <Card.Body>
                    <Card.Title className="fontAbhaya">ANIMAIS DISPONÍVEIS</Card.Title>
                    <div className="pt-3">
                      <Link to='sumario'>
                        <button
                          className="btn btn-fazenda w-75"
                        >
                          ANIMAIS DISPONÍVEIS
                        </button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </SwiperSlide>
            </Swiper>
          </div>
        </Fade>
      </div>
    </Container>
  );
}
