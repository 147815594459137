import { Box, Typography } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import pasto from './images/pasto.jpg'
import confinamento from './images/confinamento.png'
import touro from './images/touro.png'
import abate from './images/abate.png'
import rendimento from './images/rendimento.png'
import agio from './images/agio.png'

export default function Vantagens() {
  return (
    <>
      <Container>
        <Row>
          <Col xs={12} style={{ padding: "20px 0" }} className="align-items-center">
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='h5' component="h2" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '10px 0 0', md: "20px 0 0" },
                    margin: "0",
                  }}>
                    VANTAGENS
                  </Typography>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    No pasto
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Senepol é uma excelente opção para acabamento de animais a pasto. Sua capacidade de pastoreio, temperamento dócil e carne macia são ingredientes necessários para o sucesso no programa de acabamento de animais. Animais Senepol tem grande capacidade de encontrar alimentos onde nenhum outro animal encontraria, também são animais considerados de baixa manutenção nutricional.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={pasto} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>


          <Col xs={12} style={{ padding: "20px 0" }} className="align-items-center">
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    No confinamento
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    No começo dos anos 90, criadores de Senepol dos EUA em conjunto confinaram milhares de garrotes e novilhas Senepol para coletar dados e performance.
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Na fazenda de Jim Barrons Spur foram confinados mais de 2.000 animais de cruzamentos Senepol entre outubro de 90 e maio de 93. A média de dias em confinamento foi de 146 dias, com uma média de ganho de peso de 1,64 quilos / dia. O Senepol continua provando através de programas de pesquisa que é um animal de alta performance tanto em confinamento como programas de terminação a pasto.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0" style={{ padding: "20px" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={confinamento} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>


          <Col xs={12} style={{ padding: "40px 0" }}>
            <Box>
              <Typography paragraph variant='subtitle1' component="div" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '0 0 10px', md: "0 0 10px" },
                margin: "0",
              }}>
                Rentabilidade
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                O bezerro cruzado com Senepol vale 40% a mais que o zebu, gerando maior faturamento na produção.
              </Typography>
            </Box>
          </Col>


          <Col xs={12} style={{ padding: "20px 0" }} className="align-items-center">
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Maior quantidade de vacas por touro
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Um touro adulto Senepol cobre entre 50 e 60 vacas a campo, enquanto um Zebu cobre entre 25 e 30 vacas. Outro diferencial é a vida útil do reprodutor: senepol cobre a campo por até dez anos e zebu por até 4 anos. Os garrotes cobrem aos 14 meses de idade, o índice de prenhez da vacada com touro Senepol é superior a 90%.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px 0" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={touro} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>



          <Col xs={12} style={{ padding: "20px 0" }} className="align-items-center">
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Abate precoce
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Por ser uma genética de porte médio, moderado, muito adaptada aos trópicos e condições desfavoráveis de pastejo, tem excelente conversão alimentar, o que a faz ter menor exigência de energia vinda dos alimentos para sua mantença corporal, logo, sobram nutrientes para rápido crescimento e rápido acabamento de carcaça, em especial em condições adequadas de pastejo, semi-confinamento, e confinamento. É excelente opção para acabamento de animais a pasto, por serem considerados bovinos de baixa manutenção nutricional.
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Fornece facilmente a cobertura de gordura de carcaça desejada pela indústria frigorífica, e de forma bem precoce. Pesquisas feitas pela USP demonstraram que as carcaças com genética Senepol apresentaram melhor acabamento de carcaça com espessura de gordura subcutânea de 5,6 mm. Genética que permite ao pecuarista ter precocidade nos abates.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px 0" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={abate} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>



          <Col xs={12} style={{ padding: "20px 0" }} className="align-items-center">
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Abate precoce
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Os animais Senepol permitem ao pecuarista maior remuneração na venda de suas carcaças, devido o rendimento líquido da carcaça sem couro e miúdos e ganhos econômicos por premiação.
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    É genética com conformação racional e moderna para produção de carne. Tem comprimento, profundidade, e largura necessários para se “colocar” carne na carcaça. Pernas curtas para o necessário deslocamento e cobertura sem o excesso de colocação de ossatura na carcaça (para o pecuarista, nas pernas não se deposita carne). É naturalmente musculoso, e com grande percentual de carne nas partes mais nobres.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0" style={{ padding: "20px 0" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={rendimento} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>


          <Col xs={12} style={{ padding: "40px 0" }}>
            <Box>
              <Typography paragraph variant='subtitle1' component="div" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '0 0 10px', md: "0 0 10px" },
                margin: "0",
              }}>
                Taurino que acompanha a vacada a campo
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                O Senepol é um taurino que acompanha a vacada a campo, o que é um grande diferencial, afinal, 95% da pecuária de corte ainda se utiliza da monta natural. A adaptabilidade dos animais aos trópicos, sem dúvida alguma, faz com que o Senepol saia na frente das demais raças taurinas que não se adaptam ao nosso clima tropical e nem acompanham a vaca a campo. No Brasil, 93% das prenhezes resultam de monta natural e, para se obter a heterose desejada entre taurinos e zebuínos, o Senepol pode ser uma solução interessante.
              </Typography>
            </Box>
          </Col>


          <Col xs={12} style={{ padding: "40px 0" }}>
            <Box>
              <Typography paragraph variant='subtitle1' component="div" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '0 0 10px', md: "0 0 10px" },
                margin: "0",
              }}>
                Segurança: Reduz o índice de acidentes com mão de obra e infraestrutura
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                Por ser um animal muito dócil o manejo do Senepol é simples. Essa característica favorece na preservação do patrimônio, não destrói as instalações (curral, brete, porteira, etc). Reduzindo a zero os índices de acidentes com mão de obra.
              </Typography>
            </Box>
          </Col>


          <Col xs={12} style={{ padding: "20px 0" }} className="align-items-center">
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Maior premiação por carcaça

                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Os animais Senepol permitem ao pecuarista maior remuneração na venda de suas carcaças, devido o rendimento líquido da carcaça sem couro e miúdos e ganhos econômicos por premiação.
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    O Senepol permite ao pecuarista maior remuneração na venda de suas carcaças por rendimento líquido da carcaça sem couro e miúdos e ganhos econômicos por premiação. É genética com conformação racional e moderna para produção de carne. Tem comprimento, profundidade, e largura necessários para se “colocar” carne na carcaça. Pernas curtas para o necessário deslocamento e cobertura sem o excesso de colocação de ossatura na carcaça (para o pecuarista, nas pernas não se deposita carne). É naturalmente musculoso, e com grande percentual de carne nas partes mais nobres. Por medição de ultrassom apresenta elevado percentual de AOL (área de olho de lombo). Medições de carcaças com a genética Senepol feitas pela USP obtiveram em média 81,8 2 cm.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px 0" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={agio} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>



        </Row>
      </Container>
    </>
  )
}