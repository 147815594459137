import { useEffect } from "react";
import "./style.css";

export default function PoliticaPrivacidade(props) {
  useEffect(() => {
    document.title = "Politica de Privacidade";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <div id="cookies">
        <div>
          <div className="container pt-4">
            <div className="text-center">
              <h1 className="fontAbhaya pb-3 fw-bold">Política de Cookies</h1>
            </div>
            <div className="texto-politicas pb-5">
              <h2 className="fontAbhaya">
                <b>1. Você sabe o que são Cookies?</b>
              </h2>
              <p>
                <b>1.1. </b>
                <span>
                  São pequenos arquivos criados pelos websites visitados por
                  Você e que ficam etiquetados no seu navegador. Eles economizam
                  informações de navegação para tornar a sua experiência online
                  mais customizada, fácil e rápida. Através deles, Você pode se
                  manter conectado, ter suas preferências lembradas e ver
                  conteúdo local relevante de forma mais prática.
                </span>
              </p>
              <h2 className="fontAbhaya">
                <b>2. Por que utilizamos cookies?</b>
              </h2>
              <p>
                <b>2.1. </b>
                <span>
                  Nós usamos os cookies para entender, proteger, operar,
                  customizar e disponibilizar nossos serviços, bem como para
                  melhorar a sua experiência com o nosso
                </span>
                <i>
                  <span> website e blog</span>
                </i>
                <span>
                  , entender como nossos serviços estão sendo acessados e
                  usados, lembrar suas escolhas, classificar a popularidade das
                  nossas páginas e entender a sua eficácia para melhor prover
                  nossos serviços para Você.
                </span>
              </p>
              <h2 className="fontAbhaya">
                <b>
                  3. Quais tipos de cookies a FAZENDA TH SENEPOL utiliza?
                </b>
              </h2>
              <p>
                <b>3.1. </b>
                <span>Cookies estritamente necessários:</span>
                <span>
                  &nbsp;Utilizados para oferecimento de funcionalidade e
                  proteção dos sites e serviços da FAZENDA TH SENEPOL, a fim
                  de garantir funcionalidades essenciais, medir e analisar o
                  tráfego do
                </span>
                <i>
                  <span> website e blog </span>
                </i>
                <span>de forma agregada e manter os&nbsp;</span>
                <i>
                  <span> website e blog </span>
                </i>
                <span>estáveis (p. exe.: autenticação de login).</span>
              </p>
              <p>
                <b>3.2. </b>
                <span>Cookies de funcionalidade e desempenho:</span>
                <span>&nbsp;Utilizados para melhorar a funcionalidade do </span>
                <i>
                  <span> website e blog </span>
                </i>
                <span>
                  da organização (p. exe.: compartilhamento de conteúdo nas
                  redes sociais), bem como para customizar a sua experiência no
                  nosso website, blog, registrar problemas e dificuldades na sua
                  utilização e acesso e aferir a eficácia da publicidade que
                  disponibilizamos (p. exe.: preferência de idioma).
                </span>
              </p>
              <p>
                <b>3.3. </b>
                <span>Cookies analíticos:</span>
                <span>
                  &nbsp;Utilizados para entender como Você interage com o site
                  (p. exe.: captação numérica do número de visitantes).
                </span>
              </p>
              <p>
                <b>3.4. </b>
                <span>Cookies de publicidade:</span>
                <span>
                  &nbsp;Utilizados para promover os serviços da FAZENDA TH SENEPOL em sites e blogs de terceiros, medir e monitorar a
                  eficácia as ações de marketing e personalizar publicidade de
                  forma mais eficaz. Também podem ser formadas parcerias com
                  redes de publicidade que podem coletar endereços IP e outras
                  informações de web. Essas redes acompanham suas atividades
                  online com o tempo, coletando informações de navegação do site
                  de forma automatizada, inclusive por meio de cookies. Elas
                  usam essas informações para veicular anúncios sobre produtos e
                  serviços que possam ser de seu interesse. Você pode ver esses
                  anúncios em outros sites.
                </span>
              </p>
              <h2 className="fontAbhaya">
                <b>4. Como desabilitar cookies?</b>
              </h2>
              <p>
                <b>4.1. </b>
                <span>
                  Você pode seguir as instruções fornecidas em seu navegador ou
                  aparelho de celular (geralmente localizadas em “Preferências”
                  ou “Configurações”) para alterar suas configurações de
                  cookies.
                </span>
              </p>
              <p>
                <b>4.2. </b>
                <span>
                  Por favor, tenha em mente que se Você desabilitou a opção de
                  cookies em seu navegador ou celular, o
                </span>
                <i>
                  <span> website e blog </span>
                </i>
                <span>
                  da FAZENDA TH SENEPOL poderão deixar de funcionar e a
                  utilização dos nossos serviços poderá ficar comprometida.
                </span>
              </p>
              <p>
                <b>4.3. </b>
                <span>
                  Você pode utilizar a página de navegação anônima, uma
                  configuração opcional de navegação que permite que Você
                  desative o rastreamento por sites não visitados, incluindo
                  serviços de análise estatística, redes de publicidade e
                  plataformas sociais.
                </span>
              </p>

              <h2 className="fontAbhaya">
                <b>5. Como os Cookies são armazenados?</b>
              </h2>
              <p>
                <b>5.1. </b>
                <span>
                  O seu navegador da internet armazena as suas informações para
                  uso posterior, de acordo com a tabela do item 3.
                </span>
              </p>
              <p>
                <b>5.2. </b>
                <span>
                  A qualquer tempo, você pode revogar o consentimento sobre a
                  utilização de cookies, diretamente no seu navegador. Nesse
                  caso, o
                </span>
                <i>
                  <span> website e blog </span>
                </i>
                <span>
                  da FAZENDA TH SENEPOL poderão deixar de funcionar e a
                  utilização dos nossos serviços poderá ficar comprometida.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
