
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { data } from "../../components/object/bois";
export default function Sumario() {
  useEffect(() => {
    document.title = "ANIMAIS DISPONÍVEIS";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="politica-de-privacidade">
        <div className="my-5">
          <Container>
            <Row xs={2} md={2} lg={4} className="justify-content-center">
              {data.map((data) => (
                <Col key={data.id} className="mb-3">
                  <Card>
                    <Card.Img variant="top" src={`${data.fotoPrincipal}=w400-k-iv1`} />
                    <Card.Body>
                      <Card.Title className="text-truncate">{data.nome}</Card.Title>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button
                        variant="contained"
                        className="btn-fazenda text-center"
                        as={Link}
                        to={`/sumario/${data.id}`}
                        color="success"
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Mais informações
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
