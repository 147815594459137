import { Box, Typography } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';

export default function Heterose() {
  return (
    <>
      <Container className='py-4'>
        <Row>
          <Col xs={12} style={{ padding: "20px" }}>
            <Box>
              <Typography paragraph variant='h5' component="h1" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '10px 0', md: "20px 0" }
              }}>
                HETEROSE
              </Typography>
              <Typography paragraph variant='subtitle1' component="div" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '0 0 10px', md: "0 0 20px" },
                margin: "0",
              }}>
                O Cruzamento industrial sob nova perspectiva
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                Método reconhecido como ferramenta eficaz para a produção econômica de carne a pasto nos trópicos, o cruzamento industrial sofreu vários percalços nas décadas de 80 e 90 no Brasil, principalmente pela dificuldade em se dar a necessária sequência ao processo em função da baixissíma adaptabilidade aos trópicos das raças taurinas até então conhecidas e utilizadas.
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                O Senepol através de seus méritos incomparáveis quando considerada a conjunção de características, como adaptabilidade extrema aos trópicos, qualidade de carcaça, precocidade de acabamento, fertilidade, entre outras, trouxe de volta a possibilidade de se recuperar a dimensão e o valor desta indispensável ferramenta da pecuária de corte, seja pela perspectiva da monta natural a pasto ou mesmo pela inseminação artificial.
              </Typography>
            </Box>
          </Col>
          <Col xs={12}>
            <Typography paragraph variant='subtitle1' component="div" sx={{
              fontWeight: '600',
              color: "var(--cor-fazenda)",
              padding: { xs: '0 0 10px', md: "0 0 20px" },
              margin: "0",
            }}>
              O que é Heterose?
            </Typography>
            <Typography paragraph variant="body1" component="div" sx={{
              textAlign: "justify"
            }}>
              Também chamada de vigor híbrido ou choque sanguíneo, a heterose é um fenômeno pelo qual os filhos provenientes de cruzamento de raças diferentes apresentam melhores desempenhos, como maior produtividade, resistência e precocidade que seus pais.
            </Typography>
            <Typography paragraph variant="body1" component="div" sx={{
              textAlign: "justify"
            }}>
              A Heterose será mais pronunciada quanto maior a distância genética entre as raças envolvidas no cruzamento com benefício máximo quando se cruza um taurino com um zebuíno.
            </Typography>
            <Box sx={{
              height: {
                sx: "350px",
                md: "550px"
              }
            }}>
              <iframe src="https://www.youtube.com/embed/XRS_hldTppI" title="Heterose a pasto - ABCB Senepol" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className='w-100 h-100'></iframe>
            </Box>
          </Col>
          <Col xs={12}>
            <Box>
              <Typography paragraph variant='subtitle1' component="div" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '0 0 10px', md: "0 0 20px" },
                margin: "0",
              }}>
                Quais benefícios econômicos a Heterose proporciona?
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                Os ganhos pelo efeito da Heterose ocorrem pela conjunção de fatores gerados pelo vigor híbrido, os quais têm influência positiva e direta capaz de melhorar em até 30% o desempenho das características produtivas, como peso ao desmame e antecipação da idade de abate com incremento do rendimento e acabamento de carcaça nos machos e fêmeas; assim como a melhoria significativa dos índices reprodutivos, como fertilidade e habilidade materna nas fêmeas; assim como a melhoria significativa dos índices reprodutivos, como fertilidade e habilidade materna nas fêmeas. Tudo isso sem perder a tão necessária rusticidade e adaptabilidade ao ambiente tropical.
              </Typography>
            </Box>
          </Col>
          <Col xs={12}>
            <Box>
              <Typography paragraph variant='subtitle1' component="div" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '0 0 10px', md: "0 0 20px" },
                margin: "0",
              }}>
                Como fazer com que a Heterose produza carne vermelha de forma simples, natural e lucrativa nos trópicos?
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                Utilizando-se da monta natural, pelo cruzamento de um taurino que seja 100% adaptado aos trópicos com matrizes de raças Zebuínas e seus cruzamentos (F1).
              </Typography>
            </Box>
          </Col>
          <Col xs={12}>
            <Box>
              <Typography paragraph variant='subtitle1' component="div" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '0 0 10px', md: "0 0 20px" },
                margin: "0",
              }}>
                Então, por que utilizar o Senepol?
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                Por ser 100% taurino, com total adaptabilidade aos trópicos e portador de alta precocidade, qualidade de carne e de carcaça.
              </Typography>
            </Box>
          </Col>
        </Row>
      </Container>
    </>
  )
}