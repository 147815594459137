export const data = [
  {
    id: 1,
    nome: "TH0045 BILU TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1bjj6Q3YRr7GTW0Wo4bKpU7dvn1M_hbNs",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bjj6Q3YRr7GTW0Wo4bKpU7dvn1M_hbNs",
      "https://lh3.googleusercontent.com/u/0/d/1bjLjY7O4cx3wPf4TIiJs_zfvyV_CiXFm",
      "https://lh3.googleusercontent.com/u/0/d/1bdELFKCQcstGfQEG-ymkF_2iGU_VgJvF",
      "https://lh3.googleusercontent.com/u/0/d/1bfBlDwyeQU_-0FcYD1wudWl-pJdwiX4v",
      "https://lh3.googleusercontent.com/u/0/d/1bg_I1nWvWR729yhfw-ikpkGzEuaLVnJj",
      "https://lh3.googleusercontent.com/u/0/d/1bgwcsHRTfusi2K9LUEZ7mwpKvvrBiKaJ",
    ],
    dados: {
      Pai: "IPORÁ BOS",
      Mae: "CREMONA DA BARRA FIV",
      Nascimento: "01/04/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 2,
    nome: "TH0058 ROCK TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1bHprPhdq0-H-KoVQiJyzeiosyPxESm7r",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bHprPhdq0-H-KoVQiJyzeiosyPxESm7r",
      "https://lh3.googleusercontent.com/u/0/d/1bN40IAZQ7mwAWiqax0_PaOWPAtBVTf4C",
      "https://lh3.googleusercontent.com/u/0/d/1bOBe7-wmmQhQAGvtk39Xw5KyGdvNvqqi",
      "https://lh3.googleusercontent.com/u/0/d/1bOcnMVsQ5fm2piQyzFYp8aSO1lcPMzkS",
      "https://lh3.googleusercontent.com/u/0/d/1bSnNEJJueq8cjNCipo3Xv55N5JakDES2",
      "https://lh3.googleusercontent.com/u/0/d/1bSuvzqa-lPLb9rY6qyZ3nFeJv0COC4Q4",
      "https://lh3.googleusercontent.com/u/0/d/1bTVplxSZk7IZo_bZBCtqRbywnTXhlRlt",
    ],
    dados: {
      Pai: "IXÉ SOLEDADE",
      Mae: "NELLY DA BARRA FIV",
      Nascimento: "24/05/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 3,
    nome: "TH0060 RAMBO TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1bVYqT8ciE62dXdkf4_4MmKsjwkL6FwYS",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bTf5JRP1GSLiVh1HrqMrXOQlYtE9chtc",
      "https://lh3.googleusercontent.com/u/0/d/1bUSnOjSA0Zi_NH98_XRiZ7Ob5rCl8fyb",
      "https://lh3.googleusercontent.com/u/0/d/1bVYqT8ciE62dXdkf4_4MmKsjwkL6FwYS",
      "https://lh3.googleusercontent.com/u/0/d/1bWmn50SgQZhf2fNsmaKQl3Y7kPwL8bWr",
      "https://lh3.googleusercontent.com/u/0/d/1bXrekqJOI34gTZTRl5Dsfo5r2oILtYej",
      "https://lh3.googleusercontent.com/u/0/d/1baQ1oLZc8ZGCTWmSso6ZYah0n-06PYN9",
      "https://lh3.googleusercontent.com/u/0/d/1balgi07yHNZ2H9eOtyyoZ7QiMHB0mQD8",
    ],
    dados: {
      Pai: "HUNTER TE",
      Mae: "CM0005 FIV",
      Nascimento: "24/06/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 4,
    nome: "TH0061 VALENTIM TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1bEh_P_8YxxOToM6fDLrDYnaowTwYdbWB",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bEh_P_8YxxOToM6fDLrDYnaowTwYdbWB",
      "https://lh3.googleusercontent.com/u/0/d/1bFB9iWThhxKritYBQ5YpT8rIDc1qqIAg",
      "https://lh3.googleusercontent.com/u/0/d/1bFDeSnA4zltJ4uUPqCDduy-_WFwAOpT1",
      "https://lh3.googleusercontent.com/u/0/d/1bGMx1UlWRuj4nkxYknXk6PdxVFkKzoH1",
    ],
    dados: {
      Pai: "AVASSALADOR DA TUB.",
      Mae: "GUCCI DA BARRA FIV",
      Nascimento: "04/06/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 5,
    nome: "TH0063 BRASIL TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1c21n7m4YOs6oWl2aJleBFDAAdZL7QZQP",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1c21n7m4YOs6oWl2aJleBFDAAdZL7QZQP",
      "https://lh3.googleusercontent.com/u/0/d/1c5-Tja2350EtYEyrLMUYTmOr8cs-qidm",
      "https://lh3.googleusercontent.com/u/0/d/1c56xO9oyUrvpF0Mb56WBJKw2yBWiOl8_",
    ],
    dados: {
      Pai: "IPORÁ BOS",
      Mae: "CREMONA DA BARRA FIV",
      Nascimento: "26/06/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 6,
    nome: "TH0077 CUBANO TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1bBTxepVu164Xia0UKoQ8cAzIgki9shm0",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bBTxepVu164Xia0UKoQ8cAzIgki9shm0",
      "https://lh3.googleusercontent.com/u/0/d/1bCXpd-U_5tnol_6cxb7wXKJzfLgyXkHQ",
      "https://lh3.googleusercontent.com/u/0/d/1bDBx9AYkkYmj3BdgYAACB14A45f0qLNb",
      "https://lh3.googleusercontent.com/u/0/d/1bDj7s8DS5dwi-1p9EQEBWD7YWD8ic48c",
      "https://lh3.googleusercontent.com/u/0/d/1bjnjcx_LfoyoTi7DvSbSYlGXz008qv9v",
      "https://lh3.googleusercontent.com/u/0/d/1bkoLFgplPHK7Ydga5AQ3IGRGlCfRPU13",
    ],
    dados: {
      Pai: "HOTÚ SOLEDADE",
      Mae: "CM 0005 FIV",
      Nascimento: "12/08/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 7,
    nome: "TH0078 JAMAICA TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1c17VDBjI3itIV63SVU65pygHuh7-ywQb",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bv7h2nKLjHS6Hr1e6KCHowDCV7V5b2Mj",
      "https://lh3.googleusercontent.com/u/0/d/1bzTR6o37bODe0sRy5I1f8hv9qVn9tsU9",
      "https://lh3.googleusercontent.com/u/0/d/1c-AXkb_ZJmPx3XUqAz7HeW3nmlkDJ-xz",
      "https://lh3.googleusercontent.com/u/0/d/1c0GjMAgbFG8iKf-CsTstcSC1L-RmmPhN",
      "https://lh3.googleusercontent.com/u/0/d/1c17VDBjI3itIV63SVU65pygHuh7-ywQb",
    ],
    dados: {
      Pai: "HOTÚ SOLEDADE",
      Mae: "CM 0005 FIV",
      Nascimento: "14/08/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 8,
    nome: "TH0080 EL SALVADOR TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1buAw0kD2yhi7WHEieb3G2oR9rzPxhdy5",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bmlKzr_H_p7wlayWRPhXbEchHCPbZTi6",
      "https://lh3.googleusercontent.com/u/0/d/1bnjJ1wIJg3lmbhXyx2zkZHRM8BrMWGYA",
      "https://lh3.googleusercontent.com/u/0/d/1bo2U2y6pMEK54uTs9uhlD713s1bCdUeE",
      "https://lh3.googleusercontent.com/u/0/d/1bpvf9QnNOfYD-B8Bt1d4InK8BJO6tk4n",
      "https://lh3.googleusercontent.com/u/0/d/1bs1cTspcJvry2FvpelujWsv7MwoRSTgv",
      "https://lh3.googleusercontent.com/u/0/d/1btdCyYAhbKkgxJlyv-L22KM3m_rl-TzS",
      "https://lh3.googleusercontent.com/u/0/d/1buAw0kD2yhi7WHEieb3G2oR9rzPxhdy5",
    ],
    dados: {
      Pai: "IPORÁ BOS",
      Mae: "HANA DA BARRA FIV",
      Nascimento: "21/08/2020",
      Sexo: "Macho"
    },
  },


  

  {
    id: 9,
    nome: "TH0081 BARBADOS TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1cFzJGRhzeOeNXKy6mdXCx_jMAoLUkMxO",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1cFzJGRhzeOeNXKy6mdXCx_jMAoLUkMxO",
      "https://lh3.googleusercontent.com/u/0/d/1cDX99c0d65__DzLtXgQGwRpcvl4sAs6d",
      "https://lh3.googleusercontent.com/u/0/d/1cE5xoacdzc32eLChbe0HZG1u6VRbMBft",
      "https://lh3.googleusercontent.com/u/0/d/1cHC4j-K8q-Vl-8adHbF6A4ntdVlYoHOf",
      "https://lh3.googleusercontent.com/u/0/d/1cIcY2Hm5h72Zwhtymi0YnTjixMqRuARf",
      "https://lh3.googleusercontent.com/u/0/d/1cJSzq12qifSJFCjMnOu2NT_VDRVPmLMJ",
    ],
    dados: {
      Pai: "URERÉ SOLEDADE",
      Mae: "MATA 351 FIV",
      Nascimento: "21/08/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 10,
    nome: "TH0085 HAITI TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1cDTu0nmR-Cb2ugEopT5GJejRU5nSYSM7",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1cDTu0nmR-Cb2ugEopT5GJejRU5nSYSM7",
      "https://lh3.googleusercontent.com/u/0/d/1cCogCtC_5JAFg3udP4QQR1VGZwVw9lHo",
      "https://lh3.googleusercontent.com/u/0/d/1cBJvlVdCdftvjbeM4EXahpYQpZvRJEMo",
      "https://lh3.googleusercontent.com/u/0/d/1cAmQ_HMjVzG2Urvx-JtoSS3bL_CL8S2u",
      "https://lh3.googleusercontent.com/u/0/d/1cAVgS66sXrdQ1SNBcVYM5MBk0Kn8LiK7",
      "https://lh3.googleusercontent.com/u/0/d/1c8kJuG0qQQBlFFLlVF0gcPE1ubxqtGAD",
      "https://lh3.googleusercontent.com/u/0/d/1c7Jlzxtj-oJUEXg4C_OFOikXlzBb3GaW",
      "https://lh3.googleusercontent.com/u/0/d/1c6cfHCI66n8te_y8wJ5Q_G6qj_gnkt02",
    ],
    dados: {
      Pai: "IPORÁ BOS",
      Mae: "HANA DA BARRA",
      Nascimento: "24/08/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 11,
    nome: "TH0089 TOBAGO TE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1c5Wsf1gckqq3n3_DRjg3Z5_4BFWWDFl1",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1c5Wsf1gckqq3n3_DRjg3Z5_4BFWWDFl1",
      "https://lh3.googleusercontent.com/u/0/d/1cckV7sabF4vgC8Abb88pCnF1ykfzdR0Q",
      "https://lh3.googleusercontent.com/u/0/d/1cge6654Sew3nEx8UxQGGzqXQHvq4YUBH",
      "https://lh3.googleusercontent.com/u/0/d/1chXeYsSoBTjMcgPd4EhP8HdCFQJw5bUt",
    ],
    dados: {
      Pai: "HOTU SOLEDADE",
      Mae: "CECI DA BARRA FIV",
      Nascimento: "17/08/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 12,
    nome: "TH0090 A BRODOSWKI",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1cWprXZ7y6BEcVA4flalcJfdt6N9u-G5-",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1cWprXZ7y6BEcVA4flalcJfdt6N9u-G5-",
      "https://lh3.googleusercontent.com/u/0/d/1cWyNAnusXXZKMevfWgW1lln9hPALt-iJ",
      "https://lh3.googleusercontent.com/u/0/d/1cUUO64LFhOvyJGbTt73FDvNZ7In0AIFw",
      "https://lh3.googleusercontent.com/u/0/d/1cXKJBihdV-6aXzM0a4cpSu6aV-l3jplu",
      "https://lh3.googleusercontent.com/u/0/d/1cVV7AlxGUCCiPwCoq1bncFK8kVL1cuaX",
      "https://lh3.googleusercontent.com/u/0/d/1cSPfL-vBHD_aEjbcYxne8fZjHwTxC_OB",
      "https://lh3.googleusercontent.com/u/0/d/1cO-dUaroAn2mjidXGkm3kx8JW3fJWKgs",
      "https://lh3.googleusercontent.com/u/0/d/1cNG8fCFGGalyj-pmaRcV8hT2LDYEPnR1",
    ],
    dados: {
      Pai: "AVASSALADOR DA TUB.",
      Mae: "CM0150 FIV",
      Nascimento: "29/10/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 13,
    nome: "TH0101",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1cLMGxEfLYltz2XpaOUf8er-9XVi7Dh0U",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1cLMGxEfLYltz2XpaOUf8er-9XVi7Dh0U",
      "https://lh3.googleusercontent.com/u/0/d/1cK18b2of7aQXHqQpe073Ek1w9hj0A7bl",
      "https://lh3.googleusercontent.com/u/0/d/1cMK7qQM1c3kQ68FL-3FOYRvDlZx1vB-G",
      "https://lh3.googleusercontent.com/u/0/d/1cMVfED6mfrWscsA0CRKFortw6c-8apsh",
    ],
    dados: {
      Pai: "HOTÚ SOLEDADE",
      Mae: "NELLY DA BARRA FIV",
      Nascimento: "08/11/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 14,
    nome: "TH0106 A POLONI FIV",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1cvlQZ_rQDS5PLjy4lZWrokLmNq-2CoaD",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bmlKzr_H_p7wlayWRPhXbEchHCPbZTi6",
      "https://lh3.googleusercontent.com/u/0/d/1cuwq3v_LFVjdjyg5bXclEqBCUpGMc3FN",
      "https://lh3.googleusercontent.com/u/0/d/1d0LejK_IIigQ4-MKH9vQroWtJ8y5LDNU",
      "https://lh3.googleusercontent.com/u/0/d/1d0Z8YBob-ZuCCLqwJspO-aCzTyTp0PJC",
      "https://lh3.googleusercontent.com/u/0/d/1d0_R0IaRjd8HXp4HbQgqMwWdBJX-U5qk",
      "https://lh3.googleusercontent.com/u/0/d/1d2ZRsnIQbKGXrW2qX2L6Q7R0CB8Bm8Jp",
    ],
    dados: {
      Pai: "HOTÚ SOLEDADE",
      Mae: "NELLY DA BARRA FIV",
      Nascimento: "12/11/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 15,
    nome: "TH0108 A LINS FIV",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1coXzBHZBT2r-2bKXGbgxIBhPqThou46X",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1coXzBHZBT2r-2bKXGbgxIBhPqThou46X",
      "https://lh3.googleusercontent.com/u/0/d/1cnvEekS199I3PoAbq8Gl4ctegY8xi23w",
      "https://lh3.googleusercontent.com/u/0/d/1cmEBbeXfXW9xV0wISu-yaNfduy8p0lOx",
      "https://lh3.googleusercontent.com/u/0/d/1ckPByBpCIHqkY5Uayu2qRxYguJrdeNVm",
      "https://lh3.googleusercontent.com/u/0/d/1ck1kg--KHhOXdejebDQ2tUUy6lNoB3vX",
    ],
    dados: {
      Pai: "HOTÚ SOLEDADE",
      Mae: "GUCCI DA BARRA FIV",
      Nascimento: "14/11/2020",
      Sexo: "Macho"
    },
  },
  {
    id: 16,
    nome: "TH0114 A SALTENCE",
    fotoPrincipal: "https://lh3.googleusercontent.com/u/0/d/1bA1mcDwAPACe9ibHkXldQ7xAAlIveXV1",
    fotos: [
      "https://lh3.googleusercontent.com/u/0/d/1bA1mcDwAPACe9ibHkXldQ7xAAlIveXV1",
      "https://lh3.googleusercontent.com/u/0/d/1b9m2xPLPDmkQo_62heioDrYupYR7zai6",
      "https://lh3.googleusercontent.com/u/0/d/1b9iSO1YlCdLtY0wgJTxj6MCWCkeQ76Wa",
      "https://lh3.googleusercontent.com/u/0/d/1b4-M6mMeq1vx5zwe8AWvJ6TImnl4NEEk",
      "https://lh3.googleusercontent.com/u/0/d/1b2nJlNr4a9q5PIpuWdYxzBuxffFe-ONk",
    ],
    dados: {
      Pai: "AVASSALADOR DA TUF",
      Mae: "BUTTERFLY DA TUF",
      Nascimento: "14/12/2020",
      Sexo: "Macho"
    },
  },
];