import { useEffect } from 'react';
import { Table } from "react-bootstrap";
import "./style.css";

export default function PoliticaPrivacidade() {
  useEffect(() => {
    document.title = "Politica de Privacidade"

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="politica-de-privacidade">
        <div>
          <div className="container pt-4">
            <div className="text-center">
              <h3 className="font-Fraunces">
                Política de Privacidade e Proteção de Dados
              </h3>
            </div>
            <div className="texto-politicas pb-5">
              <p>
                A<strong> FAZENDA TH SENEPOL. </strong>, pessoa jurídica de
                direito privado, estabelecida na Rod. Mal. Rondon, 830 -
                Oliveira, Itu/SP CEP 13312-000, comprometido em resguardar a privacidade e
                proteger os dados pessoais de seus hóspedes. Nossa organização
                entende como extremamente relevantes os registros eletrônicos e
                dados pessoais deixados por você – Titular, na utilização dos
                diversos serviços, do <strong>FAZENDA TH SENEPOL </strong>,
                servindo o presente como Aviso de Privacidade para regularizar,
                e esclarecer de forma transparente, objetiva e simples, como os
                seus dados pessoais são coletados, utilizados e protegidos.
              </p>
              <p>
                Este aviso de privacidade dispõe, entre outros assuntos, sobre:
              </p>
              <ul>
                <li>
                  <strong>Quais dados são coletados sobre você;</strong>
                </li>
                <li>
                  <strong>Como utilizamos os seus dados pessoais;</strong>
                </li>
                <li>
                  <strong>
                    Com quem compartilhamos os seus dados pessoais;
                  </strong>
                </li>
                <li>
                  <strong>Como mantemos os seus dados pessoais seguros;</strong>
                </li>
                <li>
                  <strong>
                    Por quanto tempo os seus dados pessoais serão armazenados;
                  </strong>
                </li>
                <li>
                  <strong>
                    Seus direitos como Titular de dados pessoais e a forma de
                    exercê-los.
                  </strong>
                </li>
              </ul>
              <p>
                Em caso de dúvidas adicionais ou requisições, por favor, entre
                em contato com nosso Encarregado pela Proteção de Dados por meio
                do endereço de e-mail: contato@fazendasaojoaquim.com.br
              </p>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th scope="col">
                      <strong>Agente de tratamento</strong>
                    </th>
                    <th scope="col">
                      <strong>FAZENDA TH SENEPOL.</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Papel no tratamento</td>
                    <td>Predominantemente controladora.</td>
                  </tr>
                  <tr>
                    <td>Natureza dos dados tratados</td>
                    <td>
                      Dados pessoais fornecidos pelo Titular, de natureza
                      cadastral e financeira.
                    </td>
                  </tr>
                  <tr>
                    <td>Principais finalidades do tratamento</td>
                    <td>
                      A FAZENDA TH SENEPOL utiliza dados pessoais fornecidos
                      pelo titular para o fim de cadastro e reserva de estadias.
                    </td>
                  </tr>
                  <tr>
                    <td>Compartilhamento</td>
                    <td>
                      Com empresas parceiras e fornecedores, no desenvolvimento
                      e prestação de serviços disponibilizados a você conforme
                      esta política.
                    </td>
                  </tr>
                  <tr>
                    <td>Proteção de Dados</td>
                    <td>
                      Medidas de segurança, técnicas e administrativas
                      adequadas.
                    </td>
                  </tr>
                  <tr>
                    <td>Seus direitos</td>
                    <td>
                      Confirmação da existência de tratamento, acesso, correção,
                      etc.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p>
                Este Aviso poderá ser atualizado, a qualquer tempo, pela Fazenda
                São Joaquim. mediante aviso no site.
              </p>
              <p>
                <strong>QUAIS DADOS SÃO COLETADOS SOBRE VOCÊ</strong>
              </p>
              <p>
                Para que possamos prestar os nossos serviços, solicitamos os
                seguintes dados pessoais dos usuários.
              </p>
              <p>
                <strong>Quais são estes dados?</strong>
              </p>
              <ul>
                <li>
                  <strong>
                    Dados pessoais fornecidos diretamente por você:{" "}
                  </strong>
                  Dados que você envia para nós de forma voluntária por e-mail,
                  chat, telefone, aplicações, formulários eletrônicos, website
                  etc., para utilizar os serviços de hospedagem e outros
                  correlatos. Caso você não forneça esses dados, não poderá
                  utilizar os nossos serviços.
                </li>
              </ul>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th scope="col">
                      <strong>CATEGORIAS DE DADOS</strong>
                    </th>
                    <th scope="col">
                      <strong>DADOS</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Dados de Identificação</td>
                    <td>Nome, CPF, RG, data de nascimento, sexo,&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Dados de Contato</td>
                    <td>
                      Endereço, e-mail corporativo ou pessoal, telefone
                      corporativo ou pessoal etc.
                    </td>
                  </tr>
                  <tr>
                    <td>Dados Financeiros</td>
                    <td>
                      Chave PIX, nome do titular da conta, agência e conta
                      bancária, número do cartão de crédito, número do cartão de
                      débito;
                    </td>
                  </tr>
                  <tr>
                    <td>Dados de Comunicações</td>
                    <td>
                      Cópias de e-mails/comunicações eletrônicas de/ou para um
                      indivíduo; gravações telefónicas, voice mail, cartas etc.
                    </td>
                  </tr>
                  <tr>
                    <td>Dados de Rastreamento</td>
                    <td>Informações de navegação, IPs etc.</td>
                  </tr>
                </tbody>
              </Table>
              <ul>
                <li>
                  <strong>Dados que coletamos automaticamente: </strong>Dados
                  que coletamos através de uso do site, através de integração de
                  aplicações de tecnologia, cookies, o que inclui a forma como
                  Você utiliza os nossos serviços, arquivos de diagnóstico,
                  falhas, registros de desempenho, dados de conexões e
                  dispositivos, informações sobre sistemas operacionais, dados
                  de localização, dados extraídos de cookies obrigatórios e/ou
                  opcionais. Leia nossa Política de Cookies
                </li>
              </ul>
              <p>
                Para toda a coleta de dados pessoais, A FAZENDA TH SENEPOL
                sempre seguirá as seguintes regras:
              </p>
              <ul>
                <li>
                  Apenas serão coletadas informações imprescindíveis para a
                  prestação dos serviços oferecidos, e em observância às bases
                  legais que autorizem o seu uso e à finalidade a que se destina
                </li>
                <li>
                  Qualquer autorização que coletarmos de você será acompanhada
                  do devido grau de informação e tratamento adequado.
                </li>
                <li>
                  Os dados pessoais coletados somente serão utilizados para
                  cumprir com as finalidades informadas a você;
                </li>
                <li>
                  Não coletamos dados que digam respeito à origem racial ou
                  étnica, convicção religiosa, opinião política, filiação a
                  sindicato ou a organização de caráter religioso, filosófico ou
                  político, e tampouco dado referente à saúde ou à vida sexual
                  e/ou dado genético, a menos que você nos forneça
                  voluntariamente para lhe prover algum benefício, tais como
                  informar a sua condição em relação a restrições alérgicas e
                  alimentares, deficiência física, visual, auditiva ou
                  intelectual e necessidades especiais.
                </li>
                <li>
                  Artigos neste site podem incluir conteúdo incorporado como,
                  por exemplo, vídeos, imagens, artigos, etc. Conteúdos
                  incorporados de outros sites se comportam exatamente da mesma
                  forma como se o visitante estivesse visitando o outro site.
                </li>
              </ul>
              <p>
                <strong>COMO UTILIZAMOS OS SEUS DADOS PESSOAIS</strong>
              </p>
              <p>
                Dados pessoais são tratados pelo FAZENDA TH SENEPOL para
                diversas finalidades. Abaixo apresentamos as principais
                hipóteses em que trataremos as suas informações:
              </p>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <td>
                      <strong>DADOS PESSOAIS</strong>
                    </td>
                    <td>
                      <strong>FINALIDADES</strong>
                    </td>
                    <td>
                      <strong>BASE LEGAL DE TRATAMENTO</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Dados de Identificação
                      <br />
                      Dados Financeiros
                      <br />
                      Dados de ContatoDados de Rastreamento
                    </td>
                    <td>
                      ADMINISTRAÇÃO DE SERVIÇOS E PRODUTOS (execução de
                      processos administrativos relacionados à hospedagem;
                      gerenciar a manutenção de registros gerais e
                      obrigatórios;&nbsp;)
                    </td>
                    <td>
                      Cumprimento de obrigação legal ou regulatória;
                      <br />
                      Execução de um contrato com o titular de dados pessoais.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dados de Identificação
                      <br />
                      Dados Financeiros
                      <br />
                      Dados de Contato
                      <br />
                      Dados de Comunicações Dados de Rastreamento
                    </td>
                    <td>
                      COMUNICAÇÃO COM O HÓSPEDE (informações e confirmações de
                      reservas, promoções e respostas às suas perguntas e
                      comentários)
                    </td>
                    <td>
                      Execução de um contrato com o titular de dados
                      pessoais;Consentimento;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dados de Identificação
                      <br />
                      Dados Financeiros
                      <br />
                      Dados de Contato
                      <br />
                      Dados de Rastreamento
                    </td>
                    <td>
                      MARKETING (envio e disponibilização de informações sobre
                      produtos do FAZENDA TH SENEPOL selecionados para você).
                    </td>
                    <td>
                      Consentimento;
                      <br />
                      Execução de um contrato com o titular de dados pessoais.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dados de Identificação
                      <br />
                      Dados de Contato
                      <br />
                      Dados de ComunicaçõesDados de Rastreamento
                    </td>
                    <td>
                      ATIVIDADES DE RELACIONAMENTO (respostas a dúvidas,
                      atendimento ao cliente, relacionamento, pós-venda,
                      pesquisa de satisfação)
                    </td>
                    <td>
                      Consentimento;Execução de um contrato com o titular de
                      dados pessoais;
                      <br />
                      Cumprimento de obrigação legal ou regulatória.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p>
                <strong>
                  COM QUEM COMPARTILHAREMOS OS SEUS DADOS PESSOAIS
                </strong>
              </p>
              <ol>
                <li>
                  O FAZENDA TH SENEPOL, não vende, aluga ou comercializa
                  quaisquer Dados Pessoais para terceiros.
                </li>
                <li>
                  Com empresas parceiras e fornecedores, no desenvolvimento e
                  prestação de serviços disponibilizados a você, sobretudo nos
                  processos acima informados.
                </li>
                <li>
                  Com autoridades, entidades governamentais ou outros terceiros,
                  para a proteção dos interesses do FAZENDA TH SENEPOL em
                  qualquer tipo de conflito, incluindo ações judiciais e
                  processos administrativos;
                </li>
                <li>
                  No caso de transações e alterações societárias envolvendo o
                  FAZENDA TH SENEPOL, hipótese em que a transferência das
                  informações será necessária para a continuidade dos serviços;
                  ou,
                </li>
                <li>
                  Mediante ordem judicial ou pelo requerimento de autoridades
                  administrativas que detenham competência legal para a sua
                  requisição.
                </li>
                <li>
                  Tendo em vista que a Lei Geral de Proteção de Dados&nbsp;
                  <strong>nº13.709/18</strong>&nbsp;assegura aos titulares de
                  dados pessoais diversos direitos, inclusive o de saber com
                  quem a empresa compartilhou os respectivos dados pessoais,
                  disponibilizamos o contato através do e-mail:
                  <strong> contato@fazendasaojoaquim.com.br</strong>
                </li>
              </ol>
              <p>
                <strong>COMO MANTEMOS OS SEUS DADOS PESSOAIS SEGUROS</strong>
              </p>
              <p>
                Qualquer dado pessoal em posse do FAZENDA TH SENEPOL será
                armazenado de acordo com os mais rígidos padrões de segurança, o
                que inclui a adoção dos melhores esforços no sentido de
                preservar a privacidade e proteger os dados pessoais dos
                Titulares, mas nenhuma transmissão de informações é totalmente
                segura, estando sempre suscetíveis a ocorrência de falhas
                técnicas, vírus ou ações similares. Por isso, contamos também
                com a sua responsabilidade para adoção de medidas de segurança
                razoáveis nos dispositivos e softwares que você utiliza para
                acessar os nossos produtos e serviços.
              </p>
              <p>
                De qualquer forma, em caso de incidência de episódios desta
                natureza, o FAZENDA TH SENEPOL garante o pleno e legítimo
                esforço para remediar as consequências do evento, sempre
                garantindo a devida transparência a você.
              </p>
              <p>
                Caso você identifique ou tome conhecimento de algo que
                comprometa a segurança e o tratamento inadequado dos seus dados,
                por favor entre em contato com nossa organização.
              </p>
              <p>
                <strong>
                  POR QUANTO TEMPO OS SEUS DADOS PESSOAIS SERÃO ARMAZENADOS
                </strong>
              </p>
              <p>
                Os dados pessoais tratados pelo FAZENDA TH SENEPOL serão
                eliminados quando deixarem de ser úteis para os fins para os
                quais foram coletados, ou quando você solicitar a sua
                eliminação, exceto na hipótese de necessidade de cumprimento de
                obrigação legal ou regulatória, desde que respeitados os
                requisitos de tratamento de dados, inclusive para o exercício de
                seus direitos em processos judiciais ou administrativos.
              </p>
              <p>
                <strong>SEUS DIREITOS ENQUANTO TITULAR DOS DADOS</strong>
              </p>
              <p>
                Em cumprimento à regulamentação, no que diz respeito ao
                tratamento de dados pessoais, o FAZENDA TH SENEPOL, enquanto
                Controlador de seus dados pessoais, respeita e garante ao
                Titular, a possibilidade de apresentação de solicitações
                baseadas nos seguintes direitos:
              </p>
              <ul>
                <li>Confirmação da existência de tratamento;</li>
                <li>Acesso aos dados;</li>
                <li>
                  Correção de dados incompletos, inexatos ou desatualizados;
                </li>
                <li>
                  Anonimização, bloqueio ou eliminação de dados desnecessários,
                  excessivos ou tratados em desconformidade;
                </li>
                <li>
                  Portabilidade de seus dados a outro fornecedor de serviço ou
                  produto, mediante requisição e ou autorização expressa pelo
                  Titular;
                </li>
                <li>
                  Eliminação dos dados tratados com consentimento do Titular;
                </li>
                <li>
                  Obtenção de informações sobre as entidades públicas ou
                  privadas com as quais o FAZENDA TH SENEPOL compartilhou
                  seus dados;
                </li>
                <li>
                  Informação sobre a possibilidade de não fornecer o seu
                  consentimento, bem como de ser informado sobre as
                  consequências, em caso de negativa;
                </li>
                <li>Revogação do consentimento.</li>
              </ul>
              <p>A FAZENDA TH SENEPOL ressalta a possibilidade da sua requisição ser legalmente rejeitada, seja por motivos formais (a exemplo de impossibilidade de comprovação da sua identidade) ou legais (a exemplo do pedido de exclusão de dados cuja manutenção é livre exercício de direito pela FAZENDA TH SENEPOL)</p>
              <p><strong>LEGISLAÇÃO APLICÁVEL E ALTERAÇÕES</strong></p>
              <p>Este documento foi elaborado com base na Lei Federal&nbsp;<strong>nº 13.709/2018</strong>&nbsp;– Lei Geral de Proteção de Dados LGPD.</p>
              <p>A FAZENDA TH SENEPOL reserva o direito, a seu exclusivo critério, de modificar, alterar, acrescentar ou remover partes deste documento a qualquer momento.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
