import { Box, Typography } from '@mui/material';
import { Row, Col, Container } from 'react-bootstrap';
import investimento from './images/investimento.jpg';
import padronizacao from './images/padronizacao.jpg';
import mapa from './images/mapa.png';
import peloZero from './images/pelo-zero.png';
import ndama from './images/ndama.png';
import pasto from './images/pasto.png';
import vaca from './images/vaca.png';
import touro from './images/touro.jpg';
import bezerro from './images/bezerro.jpg';
import { useEffect } from 'react';
import './style.css';
export default function Raca() {
  useEffect(() => {
    document.title = "Politica de Privacidade";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container className='py-4'>
        <Row>
          <Col xs={12} style={{ padding: "20px" }}>
            <Box>
              <Typography paragraph variant='h5' component="h1" sx={{
                fontWeight: '600',
                color: "var(--cor-fazenda)",
                padding: { xs: '10px 0', md: "20px 0" }
              }}>
                HISTÓRIA
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                Nos anos de 1800, bovinos da raça N´Dama, foram importados do Senegal, Oeste africano, para a ilha caribenha da Saint Croix, Ilhas Virgens. O N´Dama, um Bos Taurus foi uma excelente alternativa para o Caribe não só por sua resistência ao calor, insetos, parasitas e à doenças, mas também pela habilidade de sobrevivência em regiões pobres de pastagens.
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                Em 1889, Henry C. Neltropp, um do maiores criados de N´Dama possuia um rebanho de 250 cabeças, que era um rebanho de animais puros. Bromlay filho de Henry C. Neltropp queria desenvolver um bovino que combinasse aptidões a nível superiores de produção com as condições ambientais das Ilhas Virgens. Esforços anteriores em introduzir bovinos de regiões de clima temperado haviam fracassado devido ao estresse calorífico e nutricional que estes animais sofriam ao serem submetidos às condições duras de clima e pastagens da ilha.
              </Typography>
              <Typography paragraph variant="body1" component="div" sx={{
                textAlign: "justify"
              }}>
                Em 1918 foram introduzidas genéticas de Red Poll para o rebanho de Neltropp, com o intuito de melhorar a habilidade materna, fertilidade e dar caráter mocho aos animais. Esta mescla de Red Poll com animais N´Dama foi relativo sucesso para fundar a base da raça Senepol.
              </Typography>
            </Box>
          </Col>
          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='h5' component="h2" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '10px 0 0', md: "20px 0 0" },
                    margin: "0",
                  }}>
                    CARACTERÍSTICAS
                  </Typography>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Características que fazem
                    do Senepol um investimento seguro
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Os animais Senepol apresentam um rápido crescimento, isso favorece a pecuária de corte fazendo com que o ciclo de engorda seja muito curto. São características como a elevada capacidade de transformação de pasto (proteína vegetal) em carne (proteína animal) que deixa o gado pronto para o abate rapidamente, ou seja, com maturação de peso e carcaça frigorífica em idades ainda precoces. Em avaliações de GPD em centros de performance e confinamentos de campo apresenta ganhos diários de 1,5 a 2,5 Kg / Cabeça / dia, dependendo da idade de avaliação e do manejo nutricional. Desmama com 50 a 60% do peso da mãe aos oitos meses de idade em média. Os bezerros durante a desmama são pesados variando entre 220 Kg a 260 Kg fêmeas e 240 Kg a 300 Kg machos. O que permite a venda de bezerros com preço de 30 a 50% acima da média do mercado de reposição.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={investimento} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>
          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center">
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={padronizacao} alt="Padronização de produção" className='w-100' />
                  </Box>

                </Box>
              </Col>
              <Col style={{ padding: "20px" }}>
                <Typography paragraph variant='subtitle1' component="div" sx={{
                  fontWeight: '600',
                  color: "var(--cor-fazenda)",
                  padding: { xs: '0 0 10px', md: "0 0 20px" },
                  margin: "0",
                }}>
                  Padronização de produção
                </Typography>
                <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                  O Senepol tem tamanho moderado, cor padronizada e mocho. Dessa maneira permite a obtenção dos lotes com carcaças frigoríficas mais padronizadas. O caráter genético dominante faz com que suas crias nasçam naturalmente em 95% dos casos herdando características dos taurinos. Seus genes para Homeostase são dominantes nos acasalamentos, onde é muito utilizado para adequar o tricross em matrizes S1 e S2. Estudos revelam que bezerro com sangue Senepol mantém as temperaturas retais quase idênticas ao Senepol puro.
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Tolerância ao calor
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Pela origem genética aliada a um processo de seleção fechada por séculos nas ilhas caribenhas, o Senepol, tornou-se um indivíduo com alta capacidade de adaptação a diferentes ambientes.  Adapta-se a diferentes níveis de manejo da pecuária e encontra alimento em lugares que outras raças dificilmente têm capacidade de obter. Pode sobreviver sem água por vários dias, além de viver bem, em regiões pantanosas, de mata, de cerrado, áridas, de campos quentes ou frios. Os cascos pretos, fortes, e resistentes a todo tipo de solo e topografia favorecem a raça.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1">
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <Typography variant="body2" component="div">
                      O Senepol está presente em todo o Brasil, independente do clima da região
                    </Typography>
                    <img src={mapa} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>
                </Box>
              </Col>
            </Row>
          </Col>
          <Col xs={12} style={{ padding: "20px" }}>
            <Box sx={{
              width: { xs: "75%", md: "50%" },
              margin: { sx: "0 auto", md: "0 0" },
            }}>
              <img src={peloZero} alt="90% sem pelos" className='NoventaPercent'/>
            </Box>
            <Box>
              <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify", marginTop: "10px" }}>
                Os animais Senepol têm pelo zero transferem seus genes para seus filhos reduzindo a presença de animais taurinos cabeludos. No cruzamento industrial no mínimo em 90% dos acasalamentos com outros taurinos apresentam pelo zero.
              </Typography>
            </Box>
          </Col>


          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Baixo índices de infestação de ecto e endoparasitas
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    A raça N’Dama, é a única entre os taurinos naturalmente resistentes à mosca que causa a doença do sono ou Tsé-tsé no continente africano e a carrapatos, devido a rápida coagulação superficial dificultando a alimentação desses parasitas, transmite aos seus descendentes essas condições mostrando assim sua capacidade imune elevada. Uma economia no manejo dos animais devido os baixos custos de produção e maior imunidade, em condições normais.
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Alta resistência a moscas, apresentando em geral menor contagem de infestação de moscas do que a média do rebanho, por apresentar pelo curto (zero), de alta densidade de fios por 2 cm (porém não é cabeludo como a maioria dos taurinos), o que dificulta o acesso da mosca à pele do animal.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1">
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <Typography variant="body2" component="div">
                      N’Dama, ancestral africano do Senepol.
                    </Typography>
                    <img src={ndama} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>
                </Box>
              </Col>
              <Col xs={12} className="order-3">
                <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                  Em estudos e pesquisas de contagem da mosca do chifre realizado na Universidade Estadual da Carolina do Norte – USA, em rebanho Angus foi constatado 82% de infestação na contagem de mosca, enquanto animais Senepol cruzados com Angus obtiveram contagem de apenas 18% de infestação nas mesmas condições de infestação e manejo.
                </Typography>
                <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                  Na Austrália, a amostra de contagem e carrapatos confirmou menor infestação no Senepol que em qualquer outra raça de corte utilizada naquele país, mesmo quando comparados com Zebu (Brahman) e sintéticos (Santa Gertrudes).
                </Typography>
              </Col>
            </Row>
          </Col>



          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Criado a pasto sem
                    necessidade de manejo especial
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Em qualquer condição de manejo, o Senepol se adapta facilmente, e por esta característica é que vem sendo muito utilizado, com muito êxito, para compor genética nos programas de cruzamento industrial. Ao nascimento rapidamente fica em pé e já começa a mamar o colostro. Diminuindo problemas com a cria e recria de bezerros, pois o trabalho de manejo é menor. O que favorece maior segurança ao pecuarista, pois o risco de perda do rebanho por qualquer intempérie, ou condição desfavorável de clima e manejo quando comparado a outras raças é bem menor. Criadores tem aumentado o número de animais sobreviventes ao parto quando se introduz o Senepol em seus rebanhos.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={pasto} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>



          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Longevidade, Vacas com
                    15-20 anos ainda em produção
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    O gado Senepol apresenta um rebanho totalmente produtivo e longevo. Existem citações de matrizes na ilha Saint Croix que com 18 a 20 anos ainda estão emprenhando e em lotes de produção. Touros reprodutores permanecem nos planteis de reprodução como aptos à função por muitos anos.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0" style={{ padding: "20px" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={vaca} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>


          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='h5' component="h2" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '10px 0 0', md: "20px 0 0" },
                    margin: "0",
                  }}>
                    FERTILIDADE
                  </Typography>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Alto desempenho
                    reprodutivo e libido
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Os touros Senepol cobrem a campo e sob calor, inclusive nas horas mais quentes do dia. As altas taxas de libido e dominância nos cruzamentos quando juntos com lotes de outras raças bovinas. As Proporção elevadas dos machos em relação a fêmeas é de até 1:50 contra proporções de outras raças de 1:25 a 30. As fêmeas são muito férteis, o que favorece a menor quantidade de matrizes vazias, e, portanto improdutivas na fazenda. As matrizes Senepol são boas matriarcas, com elevado instinto de cria e cuidados para com a progênie, sem serem agressivas com a mão de obra que assiste as parições e faz o manejo de cura dos umbigos.
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Estudos feitos na Universidade Estadual da Carolina do Norte – USA detectou que o nível de estresse em produtos oriundos de cruzamentos com Senepol foi menor, que quando comparado com outras raças, inclusive o zebu, resultando em melhor aptidão para produzir leite para amamentar o bezerro.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={touro} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>



          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Alto índice de confirmação de prenhezes por monta natural
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    Com o umbigo curto por natureza, pernas pequenas, carcaça muito comprida ajuda na fecundação, além de ser muito esperto e ágil no momento da cópula. Peso estável quando adulto, prolonga a vida reprodutiva do touro, quando comparado com outras raças. Segue a vaca no cio, onde ela estiver, no pântano, na mata, no cerrado, na serra, no árido, nos campos frios. Quando em lotes junto com outros reprodutores de outras raças, geralmente é dominante, sendo o primeiro a cruzar com a matriz. Os reprodutores de outras raças somente cobrem depois de muito tempo e quando o Senepol já saiu em busca de outra vaca no cio para copular. Existem indivíduos raçadores de centrais que produzem até 1.000 doses viáveis de sêmen por ejaculação, quando a média é de 250 a 300 doses.
                  </Typography>
                </Box>
              </Col>
            </Row>
          </Col>


          <Col xs={12} style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} lg={6} className="order-1">
                <Box>
                  <Typography paragraph variant='subtitle1' component="div" sx={{
                    fontWeight: '600',
                    color: "var(--cor-fazenda)",
                    padding: { xs: '0 0 10px', md: "0 0 20px" },
                    margin: "0",
                  }}>
                    Desmama com maior peso
                  </Typography>
                  <Typography paragraph variant="body1" component="div" sx={{ textAlign: "justify" }}>
                    As fêmeas de Senepol produzem boa quantidade de leite, e desmama bezerra vária aos oito meses, pesando de 50 a 60 % do peso corporal da mãe. Período de lactação de 268 dias e média em pico de lactação de até 11,3 Kg leite. As matrizes são boas matriarcas, com elevado instinto de cria e cuidados para com a progênie, sem serem agressivas com a mão de obra que assiste as parições e faz o manejo de cura dos umbigos. Estudos feitos na Universidade Estadual da Carolina do Norte – USA detectou que o nível de estresse em produtos oriundos de cruzamentos com Senepol foi menor, que quando comparado com outras raças, inclusive o zebu, resultando em melhor aptidão para produzir leite para amamentar o bezerro.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} lg={6} className="justify-content-center align-content-center align-items-center order-0 order-lg-1" style={{ padding: "20px" }}>
                <Box sx={{
                  width: { xs: "50%", lg: "100%" },
                  display: "flex",
                  textAlign: "center",
                  margin: "0 auto",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Box sx={{
                    width: { xs: "100%", lg: "75%" }
                  }}>
                    <img src={bezerro} alt="Senepol um investimento seguro" className='w-100' />
                  </Box>

                </Box>
              </Col>
            </Row>
          </Col>


        </Row>
      </Container>
    </>
  )
}