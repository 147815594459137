import { Container } from "react-bootstrap";
import "./style.css";
import React from "react";
import Fade from "react-reveal/Fade";
export default function Sobre({ children }) {
  return (
    <div id="A_Fazenda">
      <div id="sobre">
        <Container>
          <div id="fundo_branco_texto">
            <div className="conteudo">{children}</div>
            <div className="sobre-a-fazenda row" id='th-senepol'>
              <div className="col-xl-7 col-lg-9 col-12 content">
                <Fade left distance="30px">
                  
                  <h1 className="fontAbhaya titulo-sobre fw-bold">
                    Senepol TH
                  </h1>
                  <p className="fw-bold subtitulo h5 cor-secondaria">
                  Pioneirismo é a nossa Genética
                  </p>
                </Fade>
                <div id="texto-sobre-a-fazenda">
                  <Fade left distance="30px">
                    <p>
                      <span>
                      A Senepol TH, criadora bovina focada na raça Senepol, iniciou seu trabalho em 2020 na Fazenda São Joaquim em Itu-SP.
                      </span>
                    </p>

                    <p>
                      <span>
                      Com critérios bem definidos, produzimos bezerros PO provindos das melhores genéticas da pecuária moderna.
                      </span>
                    </p>
                    <p>
                      <span>
                      Através de trabalho, pesquisa e respeito ao meio ambiente, a nossa criação busca desenvolver uma raça forte, rústica e adaptada, através de doadoras, touros, sêmen e embriões com uma genética de altíssima qualidade.
                      </span>
                    </p>
                    <p>
                      <span>
                        Faça-nos uma visita e encante-se com a beleza dos nossos
                        espaços naturais!
                      </span>
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
